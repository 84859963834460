<template>
    <b-overlay :show="loading">
        <b-row class="justify-content-center">
            <b-col sm="12" md="11">
                <b-card>
                    <form-tanggal @submit="submit"></form-tanggal>
                </b-card>
            </b-col>
        </b-row>
    </b-overlay>
</template>
<script>
import {BFormSelect, BTable, BOverlay, BRow, BCol, BCard, BButton, BFormGroup, BFormInput} from 'bootstrap-vue'
import FormTanggal from './components/FormTanggal'
import vSelect from 'vue-select'
export default {
    components: {
        FormTanggal,
        vSelect,
        BFormSelect, BTable, BOverlay, BRow, BCol, BCard, BButton, BFormGroup, BFormInput
    },
    computed: {
        isValidForm() {
            if(!this.form.bulan || !this.form.tahun ) {
                return false
            }

            return true
        }
    },
    data: () => ({
        bulanOptions: [
            {
                value: '01',
                text: 'Januari'
            },
            {
                value: '02',
                text: 'Februari'
            },
            {
                value: '03',
                text: 'Maret'
            },
            {
                value: '04',
                text: 'April'
            },
            {
                value: '05',
                text: 'Mei'
            },
            {
                value: '06',
                text: 'Juni'
            },
            {
                value: '07',
                text: 'Juli'
            },
            {
                value: '08',
                text: 'Agustus'
            },
            {
                value: '09',
                text: 'September'
            },
            {
                value: '10',
                text: 'Oktober'
            },
            {
                value: '11',
                text: 'November'
            },
            {
                value: '12',
                text: 'Desember'
            }
        ],
        salesOptions: [],
        selectedSales: null,
        loading: false,
        form: {
            tgl_awal: null,
            tgl_akhir: null
        },
        fields: [
            { key: 'order', label: '#' },
            { key: 'tanggal', label: 'Tanggal' },
            { key: 'nomor', label: 'Nomor' },
            { key: 'akun', label: 'Akun' },
            { key: 'debit', label: 'Debit' },
            { key: 'kredit', label: 'Kredit' },
            { key: 'total', label: 'Total' }
        ]
    }),
    methods: {
        async submit({tgl_awal, tgl_akhir}) {
            try {

                this.loading = true
                this.form.tgl_awal = tgl_awal
                this.form.tgl_akhir = tgl_akhir
                const response = await this.$store.dispatch('laporan/getModal', this.form)
                window.open(URL.createObjectURL(response))
                this.loading = false
            }
            catch(e) {
                this.loading = false
                this.displayError(e)
                return false
            }
        }
    }
}
</script>